<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Payment Date From</label>
                <DatePicker v-model="state.optionFields.paymentDateFrom.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Payment Date To</label>
                <DatePicker v-model="state.optionFields.paymentDateTo.val" :input-attributes="{class: 'form-control'}"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Deposit Location</label>
                <select class="form-control" v-model="state.optionFields.depositLocation.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Sales Person</label>
                <Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.salesPersonId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>EFT</label>
                <select class="form-control" v-model="state.optionFields.eft.val">
                    <option value='0'>Include</option>
                    <option value='none'>None</option>
                    <option value='only'>Only</option>
                </select>
            </div>
          <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Credit Payments</label>
            <select class="form-control" v-model="state.optionFields.isCredit.val">
              <option value='0'>Include</option>
              <option value='1'>Exclude</option>
              <option value='unshipped'>Not Shipped</option>
              <option value='shipped'>Shipped</option>
            </select>
          </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Special Tag</label>
                <select class="form-control" v-model="state.optionFields.specialTag.val">
                    <option value='0'>All</option>
                    <option v-for="[id, specialTag] in cache.userCache.USER_SPECIAL_TAGS" v-bind:key="id" :value="id">{{ specialTag }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store.js";
    import cdiLocationCache from "@/cache/cdiLocation.cache.js";
    import userCache from "@/cache/user.cache.js";
    import DatePicker from "../utils/DatePicker";
    import Typeahead from '@/components/utils/typeaheads/Typeahead';
    export default {
        name: 'Options',
        components: {DatePicker, Typeahead},
        data() {
            return {
                state: store.state,
                cache: {
                    userCache,
                    cdiLocationCache
                }
            }
        }
    }
</script>
